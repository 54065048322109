export default {
  miningDamage: 'Schaden',
  miningToughness: 'Zähigkeit',
  miningOreGain: 'Erz-Einkommen',
  miningRareEarthGain: 'Bodenschatz-Einkommen',
  miningPickaxeCraftingPower: 'Handwerkskraft',
  miningPickaxeCraftingSlots: 'Herstellplätze',
  miningPickaxePremiumCraftingSlots: 'Premium-Herstellplätze',
  miningPickaxeCraftingQuality: 'Handwerksreinheit',
  miningOreQuality: 'Erz-Qualität',
  miningDepthDwellerSpeed: 'Tiefengräber-Tempo',
  miningDepthDwellerMax: 'Höchstmögliche Gräbertiefe',
  miningSmelterySpeed: 'Hochofen-Tempo',
  miningSmelteryTemperature: 'Hochofen-Temperatur',
  miningResinMax: 'Maximales Harz',
  currencyMiningHeliumIncrement: 'Helium-Anstieg',
  currencyMiningNeonIncrement: 'Neon-Anstieg',
  currencyMiningArgonIncrement: 'Argon-Anstieg',
  currencyMiningKryptonIncrement: 'Krypton-Anstieg',
  currencyMiningXenonIncrement: 'Xenon-Anstieg',
  currencyMiningRadonIncrement: 'Radon-Anstieg',
  miningEnhancementBarsIncrement: 'Barren-Anstieg',
  miningEnhancementFinalIncrement: 'Aufwertungs-Anstieg',
  villageWorker: 'Arbeiter',
  villageTaxRate: 'Steuersatz',
  queueSpeedVillageBuilding: 'Bautempo',
  villageOfferingPower: 'Opfergaben-Kraft',
  villageResourceGain: 'Gesamtes Rohstoffeinkommen',
  villageMaterialGain: 'Material-Einkommen',
  villageMaterialCap: 'Material-Kapazität',
  villageFoodGain: 'Nahrungs-Einkommen',
  villageMentalGain: 'Mentale-Ressourcen-Einkommen',
  villageHappiness: 'Zufriedenheit',
  villagePolicyTaxes: 'Steuerverordnung',
  villagePolicyImmigration: 'Einwanderungsverordnung',
  villagePolicyReligion: 'Religionsverordnung',
  villagePolicyScanning: 'Scanverordnung',
  villageHousingCap: 'Maximale Wohnraum-Stufe',
  villageWorkstationCap: 'Maximale Arbeitsplatz-Stufe',
  villagePollution: 'Verschmutzung',
  villagePollutionTolerance: 'Verschmutzungstoleranz',
  villagePower: 'Energie',
  villageLootGain: 'Beuteeinkommen',
  villageLootQuality: 'Beutequalität',
  hordeAttack: 'Angriff',
  hordeHealth: 'Leben',
  hordeRecovery: 'Erholung',
  hordeCritChance: 'Kritische Chance',
  hordeCritMult: 'Kritischer Schaden',
  hordeRevive: 'Wiederbelebung',
  hordeMaxItems: 'Ausrüstungs-Kapazität',
  hordeToxic: 'Giftigkeit',
  hordeFirstStrike: 'Erstschlag',
  hordeSpellblade: 'Zauberklinge',
  hordeCutting: 'Schneiden',
  hordeDivisionShield: 'Teilungsschild',
  hordeStunResist: 'Betäubungsresistenz',
  hordeShieldbreak: 'Schildbruch',
  hordeEnemyActiveStart: 'Gegnerische Anfangs-Abklingzeit',
  hordePhysicConversion: 'Physische Schadenskonvertierung',
  hordeMagicConversion: 'Magische Schadenskonvertierung',
  hordeBioConversion: 'Biologische Schadenskonvertierung',
  hordePhysicAttack: 'Physischer Schaden verursacht',
  hordeMagicAttack: 'Magischer Schaden verursacht',
  hordeBioAttack: 'Biologischer Schaden verursacht',
  hordePhysicTaken: 'Physischer Schaden erlitten',
  hordeMagicTaken: 'Magischer Schaden erlitten',
  hordeBioTaken: 'Biologischer Schaden erlitten',
  hordeLoot: 'Einfache Beute',
  hordeItemChance: 'Ausrüstungs-Chance',
  hordeBossRequirement: 'Boss-Anforderung',
  hordeRespawn: 'Wiedererscheinungszeit',
  hordeHeirloomChance: 'Erbstück-Chance',
  hordeHeirloomAmount: 'Erbstück-Höchsteinkommen',
  hordeHeirloomEffect: 'Erbstück-Effekt',
  hordeNostalgia: 'Nostalgie',
  hordeCorruption: 'Korruption',
  hordeItemMasteryGain: 'Meisterungspunkte-Einkommen',
  hordeMinibossTime: 'Miniboss-Zeit',
  hordeShardChance: 'Mystische-Scherben-Chance',
  powerHeirloomEffect: 'Kraft-Effekt',
  fortitudeHeirloomEffect: 'Resistenz-Effekt',
  wealthHeirloomEffect: 'Reichtum-Effekt',
  spiritHeirloomEffect: 'Spiritualität-Effekt',
  sharpsightHeirloomEffect: 'Adlerauge-Effekt',
  holdingHeirloomEffect: 'Speicher-Effekt',
  reapingHeirloomEffect: 'Sense-Effekt',
  remembranceHeirloomEffect: 'Andenken-Effekt',
  expertiseHeirloomEffect: 'Expertise-Effekt',
  mysteryHeirloomEffect: 'Mysterium-Effekt',
  farmExperience: 'Pflanzenerfahrung',
  farmGoldChance: 'Goldchance',
  farmTiles: 'Gartenfelder',
  farmGrow: 'Wachstumszeit',
  farmOvergrow: 'Überwuchern',
  farmRareDropChance: 'Chance auf seltenen Ertrag',
  farmMystery: 'Mysterium',
  farmDnaNext: 'DNA nach Prestige',
  farmCropGain: 'Ertrag',
  farmAllGain: 'Sämtlicher Pflanzenertrag',
  galleryInspirationBase: 'Inspiration-Grundzeit',
  galleryInspirationIncrement: 'Inspiration-Zeitanstieg',
  galleryInspirationStart: 'Anfangs-Inspiration',
  galleryColorGain: 'Einkommen aller Farben',
  galleryColorDrumChance: 'Chance aller Farbtrommeln',
  galleryColorDrumCap: 'Kapazität aller Farbtrommeln',
  galleryRedConversion: 'Rote Umwandlung',
  galleryRedDrumChance: 'Rote-Farbtrommel-Chance',
  galleryOrangeConversion: 'Orange Umwandlung',
  galleryOrangeDrumChance: 'Orange-Farbtrommel-Chance',
  galleryYellowConversion: 'Gelbe Umwandlung',
  galleryYellowDrumChance: 'Gelbe-Farbtrommel-Chance',
  galleryGreenConversion: 'Grüne Umwandlung',
  galleryGreenDrumChance: 'Grüne-Farbtrommel-Chance',
  galleryBlueConversion: 'Blaue Umwandlung',
  galleryBlueDrumChance: 'Blaue-Farbtrommel-Chance',
  galleryPurpleConversion: 'Lila Umwandlung',
  galleryPurpleDrumChance: 'Lila-Farbtrommel-Chance',
  "galleryDeep-orangeConversion": 'Rotorange Umwandlung',
  "galleryDeep-orangeDrumChance": 'Rotorange-Farbtrommel-Chance',
  galleryAmberConversion: 'Goldene Umwandlung',
  galleryAmberDrumChance: 'Goldene-Farbtrommel-Chance',
  "galleryLight-greenConversion": 'Hellgrüne Umwandlung',
  "galleryLight-greenDrumChance": 'Hellgrüne-Farbtrommel-Chance',
  galleryTealConversion: 'Türkise Umwandlung',
  galleryTealDrumChance: 'Türkise-Farbtrommel-Chance',
  "galleryLight-blueConversion": 'Hellblaue Umwandlung',
  "galleryLight-blueDrumChance": 'Hellblaue-Farbtrommel-Chance',
  galleryPinkConversion: 'Pinke Umwandlung',
  galleryPinkDrumChance: 'Pinke-Farbtrommel-Chance',
  miningCardCap: 'Bergbau-Kartenlimit',
  villageCardCap: 'Dorf-Kartenlimit',
  hordeCardCap: 'Horde-Kartenlimit',
  galleryCardCap: 'Gallerie-Kartenlimit',
  treasureSlots: 'Schatzplätze',
  cryolabMaxFeatures: 'Maximale eingefrorene Funktionen',
  merchantOffers: 'Händlerangebote',
  bankInvestmentSize: 'Investitionsgröße',
  bankLoanSize: 'Kreditgröße',
  bankCardPackChance: 'Bank-Kartenpaket-Chance',
  cindersProductionFirefly: 'Licht-Einkommen (Glühwürmchen)',
  cindersProductionGlowshroom: 'Licht-Einkommen (Leuchtpilz)',
  cindersProductionGlowfish: 'Licht-Einkommen (Neonfisch)',
  cindersProductionLantern: 'Licht-Einkommen (Laterne)',
  cindersProductionCampfire: 'Licht-Einkommen (Lagerfeuer)',
  cindersProductionCoral: 'Licht-Einkommen (Koralle)',
  cindersProductionJellyfish: 'Licht-Einkommen (Qualle)',
  cindersProductionNightbloom: 'Licht-Einkommen (Nachtblüher)',
  cindersProductionNeonlight: 'Licht-Einkommen (Neonbeleuchtung)',
  cindersProductionSun: 'Licht-Einkommen (Sonne)',
  cindersFirstProducerCost: 'Kosten des ersten Produzenten',
  cindersNonFirstProducerCost: 'Kosten weiterer Produzenten',
  cindersUpgradeLightCost: 'Verbesserungs-Lichtkosten',
  cindersUpgradeProducerRequirement: 'Produzenten-Verbesserungs-Anforderung',
  cindersCandlePower: 'Kerzenkraft',
  bloomInventorySize: 'Inventargröße',
  bloomBreederSize: 'Zuchtstationen',
  bloomDaisyChance: 'Gänseblümchen-Stufenchance',
  bloomPoppyChance: 'Mohn-Stufenchance',
  bloomIrisChance: 'Schwertlilie-Stufenchance',
  bloomLilyChance: 'Lilie-Stufenchance',
  bloomOrchidChance: 'Orchidee-Stufenchance',
  bloomCornflowerChance: 'Kornblume-Stufenchance',
  bloomDaisyBreedTime: 'Gänseblümchen-Zuchtzeit',
  bloomPoppyBreedTime: 'Mohn-Zuchtzeit',
  bloomIrisBreedTime: 'Schwertlilie-Zuchtzeit',
  bloomLilyBreedTime: 'Lilie-Zuchtzeit',
  bloomOrchidBreedTime: 'Orchidee-Zuchtzeit',
  bloomCornflowerBreedTime: 'Kornblume-Zuchtzeit',
  weatherChaosFishingPower: 'Angelkraft',
  weatherChaosFishSizeMax: 'Maximale Fischgröße',
  weatherChaosFishSizeAverage: 'Durchschnittliche Fischgröße',
  weatherChaosFishingTime: 'Bisszeit',
  weatherChaosFishDoubleChance: 'Chance auf doppelte Fische',
  weatherChaosIgnoreWeather: 'Wetter ignorieren',
  weatherChaosFishChance: 'Fisch-Chance',
  weatherChaosTreasureChance: 'Schatz-Chance',
  weatherChaosAlgaeWeight: 'Algen-Gewicht',
  weatherChaosDriftwoodWeight: 'Treibholz-Gewicht',
  weatherChaosPlasticWeight: 'Plastik-Gewicht',
  weatherChaosTrashGain: 'Abfall-Einkommen',
  summerFestivalBuildQueueSlots: 'Bau-Plätze',
  summerFestivalBuildQueueSpeed: 'Bau-Tempo',
  summerFestivalMaterialGain: 'Material-Einkommen',
  summerFestivalMaterialStackCap: 'Material-Stapelgröße',
  nightHuntFindableIngredients: 'Mögliche Zutaten',
  nightHuntIngredientSize: 'Zutatengröße',
  nightHuntMaxIngredients: 'Ritualzutaten',
  nightHuntBonusIngredientCount: 'Bonuszutaten',
  nightHuntBonusIngredientAmount: 'Bonuszutaten-Menge',
  nightHuntRitualStability: 'Ritual-Stabilität',
  nightHuntRitualSuccessChance: 'Ritual-Erfolgschance',
  nightHuntRitualHintChance: 'Ritual-Hinweischance',
  nightHuntRitualFamiliarity: 'Ritual-Bekanntheit',
  snowdownAttack: 'Angriff',
  snowdownHealth: 'Leben',
  snowdownDefense: 'Abwehr',
  snowdownCritRating: 'Kritisch-Wertung',
  snowdownBlockRating: 'Blockwertung',
  snowdownLootRating: 'Beutewertung',
  snowdownPetAttack: 'Haustier-Angriff',
  snowdownPetHealth: 'Haustier-Leben',
  snowdownPetDefense: 'Haustier-Abwehr',
  snowdownPetCritRating: 'Haustier-Kritisch-Wertung',
  snowdownPetBlockRating: 'Haustier-Blockwertung',
  snowdownAllAttack: 'Gesamter Angriff',
  snowdownAllHealth: 'Gesamte Leben',
};
